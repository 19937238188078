import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export default class ProfileService {
  
  static async fetchProfiles(uids) {
    if (!uids || uids.length === 0) {
      return {}
    }
    var userIdToProfile = {}
    return firebase.firestore().collection("profiles").where("uid", "in", Array.from(new Set(uids))).get()
    .then(function(querySnapshot) {
      querySnapshot.docs.forEach((doc, index) => userIdToProfile[doc.data().uid]=doc.data())
      return userIdToProfile;
    })
  }

}