
export default class PermissionChecker {
  currentUser;

  constructor(currentUser) {
    this.currentUser = currentUser;
  }

  get isAuthenticated() {
    return (
      Boolean(this.currentUser)
    );
  }

  get isEmailVerified() {
    return true;
  }
}
