import authAxios from 'src/modules/shared/axios/authAxios';

export default class RestaurantService {

  static async find(id) {

    const response = await authAxios.get(
      `/restaurants/${id}`,
    );

    return response.data;
  }
}
