import authAxios from 'src/modules/shared/axios/authAxios';
import ProfileService from 'src/modules/shared/services/profileService'


export default class DishItemRatingService {
  static async create(itemId, rating: number, comment: string) {

    const response = await authAxios.post(`/ratings/`, 
    JSON.stringify({
      itemId: itemId,
      rating: rating,
      comment: comment
    })
    );

    return response.data;
  }

  static async get(itemId) {

    const response = await authAxios.get(
      `/items/${itemId}`,
    );

    return response;
  }

  private static formatRatingProfile(profile) {
    return {
      firstName: profile.firstName,
      lastName: profile.lastName,
      avatarUrl: profile.avatars && profile.avatars[0].downloadUrl
    }
  }

  static async fetchingComments(itemId) {

    const response = await authAxios.get(
      `/ratings/?item-id=${itemId}`,
    );
    if (!response.data) {
      return response.data;
    }
    let userIdToProfiles = await ProfileService.fetchProfiles(response.data.data.map(commentEntry => commentEntry.userId))
    var ratingSum = 0
    var numRatings = 0
    response.data.data.forEach((comment, index) => {
      comment.profile=(comment.userId in userIdToProfiles ? DishItemRatingService.formatRatingProfile(userIdToProfiles[comment.userId]) : null)
      let rating = comment.rating ? comment.rating : 0
      if (rating) {
        ratingSum += rating
        numRatings += 1
      }
    })
    response.data.ratings = {
      avgRating: ratingSum / numRatings,
      numRatings
    }
    return response.data
  }
}
