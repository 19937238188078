import actions from 'src/modules/dishmenu/view/dishMenuViewActions';

const initialData = {
  loading: false,
  item: null,
  comment: "",
  submitting: false,
  comments: [],
  fetchingComments: false
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.GET_STARTED) {
    return {
      ...state,
      item: null,
      loading: true,
    };
  }

  if (type === actions.GET_SUCCESS) {
    return {
      ...state,
      item: payload,
      loading: false,
    };
  }

  if (type === actions.GET_ERROR) {
    return {
      ...state,
      item: null,
      loading: false,
    };
  }
  
  if (type === actions.TYPE_COMMENT) {
    return {
      ...state,
      comment: payload.comment
    };
  }

  if (type === actions.SUBMIT_COMMENT_STARTED) {
    return {
      ...state,
      submitting: payload.submitting
    };
  }

  if (type === actions.SUBMIT_COMMENT_SUCCESS) {
    return {
      ...state,
      submitting: payload.submitting
    };
  }

  if (type === actions.GET_COMMENTS_STARTED) {
    return {
      ...state,
      fetchingComments: payload.fetchingComments
    }
  }

  if (type === actions.GET_COMMENTS_SUCCESS) {
    
    return {
      ...state,
      fetchingComments: payload.fetchingComments,
      comments: payload.comments,
      ratings: payload.ratings
    }
  }

  return state;
};
