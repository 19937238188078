import authAxios from 'src/modules/shared/axios/authAxios';

export default class DishMenuService {
  static async find(restaurantId, tableId?) {
    var request = `/menus/?restaurant-id=${restaurantId}`;
    if (tableId) {
      request = `/menus/?restaurant-id=${restaurantId}&table-id=${tableId}`
    }
    const response = await authAxios.get(request);
  
    return response.data;
  }
}
