import RestaurantService from 'src/modules/dishmenu/restaurantService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'DISH_MENU_HEADER';

const actions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  doFetch: (id) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: actions.FETCH_STARTED
      });

      const response = await RestaurantService.find(id);

      dispatch({
        type: actions.FETCH_SUCCESS,
        payload: {
          restaurant: response.data
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FETCH_ERROR,
      });
    }
  },
};

export default actions;
