import service from 'src/modules/merchantStoreManage/storeManageService';
import selectors from 'src/modules/merchantauth/merchantAuthSelectors'
import Errors from 'src/modules/shared/error/errors';

const prefix = 'MERCHANT_STORE_MANAGE';

const actions = {
  GET_ALL_STORES_START: `${prefix}_GET_ALL_STORES_START`,
  GET_ALL_STORES_SUCCESS: `${prefix}_GET_ALL_STORES_SUCCESS`,
  GET_STORE_START: `${prefix}_GET_STORE_START`,
  GET_STORE_SUCCESS: `${prefix}_GET_STORE_SUCCESS`,
  CREATE_STORE_START: `${prefix}_CREATE_STORE_START`,
  CREATE_STORE_SUCCESS: `${prefix}_CREATE_STORE_SUCCESS`,
  CREATE_STORE_FAILED: `${prefix}_CREATE_STORE_FAILED`,

  doGetAllManagedStores: () => async (dispatch, getState) => {
    dispatch({ type: actions.GET_ALL_STORES_START });
    const user = selectors.selectCurrentUser(getState())
    const stores = await service.getAllManagedStores(
      user.uid);
    
    dispatch({
      type: actions.GET_ALL_STORES_SUCCESS,
      payload: {
        stores: stores.data,
      },
    });
  },
  doGetStore: (storeId) => async (dispatch, getState) => {
    dispatch({ type: actions.GET_STORE_START });
      const user = selectors.selectCurrentUser(getState())
      const store = await service.getStore(
        user.uid, storeId);
      
      dispatch({
        type: actions.GET_STORE_SUCCESS,
        payload: {
          store: store.data,
        },
      });
  },

  doCreateStore: (storeName, hideCreateModal, storeAddress?, storePhoneNumber?) => async (dispatch, getState) => {
    try {
      dispatch({ type: actions.CREATE_STORE_START });
      const merchant = selectors.selectCurrentUser(getState())
      await service.create(
        merchant.uid, storeName, storeAddress, storePhoneNumber);
      
      dispatch({
        type: actions.CREATE_STORE_SUCCESS,
        payload: { },
      });
      hideCreateModal()
      dispatch(actions.doGetAllManagedStores())
    } catch (error) {
      Errors.handle(error);
      dispatch({ type: actions.CREATE_STORE_FAILED });
    }
    
  },
};

export default actions;
