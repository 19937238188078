import React from 'react';
import ReactDOM from 'react-dom';
import { i18n, init as i18nInit } from 'src/i18n';
import { AuthToken } from './modules/auth/authToken';
import SettingsService from './modules/settings/settingsService';
import * as firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB0reh97BUYlzRicOQP7GlbRaDXcq0qr6w",
  authDomain: "foodie-web-0910.firebaseapp.com",
  databaseURL: "https://foodie-web-0910.firebaseio.com",
  projectId: "foodie-web-0910",
  storageBucket: "foodie-web-0910.appspot.com",
  messagingSenderId: "436826704615",
  appId: "1:436826704615:web:d6e812c3b6f599c914b042",
  measurementId: "G-58T73WPLW3" 
};

(async function () {

  firebase.initializeApp(firebaseConfig);
  AuthToken.applyFromLocationUrlIfExists();
  SettingsService.applyThemeFromTenant();
  await i18nInit();

  document.title = i18n('app.title');
  const App = require('./App').default;
  ReactDOM.render(<App />, document.getElementById('root'));
})();
