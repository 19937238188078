import PermissionChecker from 'src/modules/auth/permissionChecker';
import React from 'react';
import {
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import Layout from 'src/view/layout/Layout';
import MerchantLayout from 'src/view/layout/MerchantLayout';

function PrivateRoute({
  component: Component,
  currentMerchant,
  currentUser,
  permissionRequired,
  ...rest
}) {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentUser,
          currentMerchant
        );
        if (!permissionChecker.isAuthenticated && !permissionChecker.isMerchantAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: { from: location },
              }}
            />
          );
        }

        if (permissionChecker.isAuthenticated) {
          return (
            <Layout {...props}>
              <Component {...props} />
            </Layout>
          );
        }
        return (
          <MerchantLayout {...props}>
            <Component {...props} />
          </MerchantLayout>
        );
        
      }}
    />
  );
}

export default PrivateRoute;
