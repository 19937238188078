import actions from 'src/modules/merchantStoreManage/storeManageActions';

const initialData = {
  stores: [],
  storesLoading: true,
  currentStore: null,
  storeLoading: true,
  storeCreating: false
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.GET_ALL_STORES_START) {
    return {
      ...state,
      stores: null,
      storesLoading: true
    };
  }

  if (type === actions.GET_ALL_STORES_SUCCESS) {
    return {
      ...state,
      stores: payload.stores,
      storesLoading: false
    };
  }

  if (type === actions.GET_STORE_START) {
    return {
      ...state,
      currentStore: payload.store,
      storeLoading: true
    };
  }

  if (type === actions.GET_STORE_SUCCESS) {
    return {
      ...state,
      currentStore: payload.store,
      storeLoading: false
    };
  }

  if (type === actions.CREATE_STORE_START) {
    return {
      ...state,
      storeCreating: true
    };
  }

  if (type === actions.CREATE_STORE_SUCCESS || type === actions.CREATE_STORE_FAILED) {
    return {
      ...state,
      storeCreating: false
    };
  }
  
  return state;
};
