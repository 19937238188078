import actions from 'src/modules/merchantTableManage/tableManageActions';

const initialData = {
  qrCode: null,
  loading: true
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.GET_QR_CODE_START) {
    return {
      ...state,
      qrCode: null,
      loading: true
    };
  }

  if (type === actions.GET_QR_CODE_SUCCESS) {
    return {
      ...state,
      qrCode: payload.qrCodeData,
      loading: false
    };
  }

  if (type === actions.GET_QR_CODE_NOT_FOUND) {
    return {
      ...state,
      qrCode: null,
      loading: false
    };
  }

  return state;
};
