import {
    LockOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    AppstoreOutlined,
    UserOutlined,
  } from '@ant-design/icons';
  import { Avatar, Dropdown, Layout, Menu } from 'antd';
  import React from 'react';
  import { useSelector, useDispatch } from 'react-redux';
  import { i18n } from 'src/i18n';
  import authActions from 'src/modules/merchantauth/merchantAuthActions';
  import authSelectors from 'src/modules/merchantauth/merchantAuthSelectors';
  import layoutActions from 'src/modules/layout/layoutActions';
  import layoutSelectors from 'src/modules/layout/layoutSelectors';
  import { getHistory } from 'src/modules/store';
  import HeaderWrapper from 'src/view/layout/styles/HeaderWrapper';
  import config from 'src/config';
  
  const { Header: AntHeader } = Layout;
  
  const Header = (props) => {
    const dispatch = useDispatch();
  
    const menuVisible = useSelector(
      layoutSelectors.selectMenuVisible,
    );
    const userDropdownAvatar = useSelector(
      authSelectors.selectCurrentUserAvatar,
    );
  
    const doSignout = () => {
      dispatch(authActions.doSignout());
    };
  
    const doNavigateToProfile = () => {
      getHistory().push({pathname : '/profile', state : {isMerchant: true}});
    };
  
    const doNavigateToPasswordChange = () => {
      getHistory().push('/password-change');
    };
    const doToggleMenu = () => {
      dispatch(layoutActions.doToggleMenu());
    };
  
    const userMenu = (
      <Menu selectedKeys={[]}>
        <Menu.Item
          onClick={doNavigateToProfile}
          key="userCenter"
        >
          <UserOutlined />
          {i18n('auth.profile.title')}
        </Menu.Item>
        <Menu.Item
          onClick={doNavigateToPasswordChange}
          key="passwordChange"
        >
          <LockOutlined />
          {i18n('auth.passwordChange.title')}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={doSignout} key="logout">
          <LogoutOutlined />
          {i18n('auth.signout')}
        </Menu.Item>
      </Menu>
    );
  
    return (
      <HeaderWrapper>
        <AntHeader>
          {menuVisible ? (
            <MenuFoldOutlined
              className="trigger"
              onClick={doToggleMenu}
            />
          ) : (
            <MenuUnfoldOutlined
              className="trigger"
              onClick={doToggleMenu}
            />
          )}
  
          <div className="header-right">
  
            <Dropdown
              className="user-dropdown"
              overlay={userMenu}
              trigger={['click']}
            >
              <div className="user-dropdown-content">
                <Avatar
                  className="user-dropdown-avatar"
                  size="small"
                  src={userDropdownAvatar || undefined}
                  alt="avatar"
                  icon={
                    userDropdownAvatar ? undefined : (
                      <UserOutlined />
                    )
                  }
                />
              </div>
            </Dropdown>
          </div>
        </AntHeader>
      </HeaderWrapper>
    );
  };
  
  export default Header;
  