import actions from 'src/modules/dishmenu/list/dishMenuListActions';

// eslint-disable-next-line
const INITIAL_PAGE_SIZE = 10;

const initialData = {
  loading: false,
  menus: [],
  currentMenuItems: {}
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.RESETED) {
    return {
      ...initialData,
      menus: [],
      currentMenuItems: {},
      loading: true
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      menus: [],
      currentMenuItems: {},
    };
  }

  if (type === actions.FETCH_ALL_SUCCESS) {
    
    return {
      ...state,
      loading: false,
      menus: payload.menus,
      currentMenuItems: payload.items,
    };
  }

  if (type === actions.FETCH_NEW_MENU_SUCCESS) {
    return {
      ...state,
      loading: false,
      currentMenuItems: payload.items,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      menus: [],
      currentMenuItems: {},
    };
  }

  return state;
};
