import authAxios from 'src/modules/shared/axios/authAxios';
import { MerchantAuthToken } from 'src/modules/merchantauth/merchantAuthToken';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";


export default class AuthService {
  
  static async sendEmailVerification() {
    const response = await authAxios.post(
      '/auth/send-email-address-verification-email',
      {
        tenantId: undefined,
      }
    );

    return response.data;
  }

  static async sendPasswordResetEmail(email) {
    const response = await authAxios.post(
      '/auth/send-password-reset-email',
      {
        email,
        tenantId: undefined,
      },
    );

    return response.data;
  }

  static async registerWithEmailAndPassword(
    email,
    password,
  ) {

    return firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.error(`Got error ${errorCode}: ${errorMessage}`)
      // ...
    }).then(_ => firebase.auth().currentUser?.getIdToken(true));
    
  }

  static async registerProfile(
    merchantName, address, firstName, lastName, email, password, phoneNumber, invitationCode?
  ) {
    
    return firebase.firestore().collection("merchantProfiles").doc(firebase.auth().currentUser?.uid).set({
      uid: firebase.auth().currentUser?.uid,
      merchantName: merchantName,
      address: address,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      invitationCode: invitationCode !== undefined ? invitationCode : ""
  })
  }

  static async signinWithEmailAndPassword(email, password) {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    return firebase.auth().signInWithEmailAndPassword(email, password)
    .then(_ => firebase.auth().currentUser?.getIdToken(true));
  }

  static async fetchMe(uid) {
    return firebase.firestore().collection("merchantProfiles").where("uid", "==", uid).get()
    .then(function(querySnapshot) {
      return querySnapshot.docs[0].data();
    })
  }

  static async isEmailConfigured() {
    const response = await authAxios.get(
      '/auth/email-configured',
    );
    return response.data;
  }

  static signout() {
    MerchantAuthToken.set(null, true);
  }

  static async updateProfile(data) {
    const body = {
      data,
    };

    const response = await authAxios.put(
      '/auth/profile',
      body,
    );

    return response.data;
  }

  static async changePassword(oldPassword, newPassword) {
    const body = {
      oldPassword,
      newPassword,
    };

    const response = await authAxios.put(
      '/auth/change-password',
      body,
    );

    return response.data;
  }

  static async passwordReset(token, password) {
    const response = await authAxios.put(
      '/auth/password-reset',
      {
        token,
        password,
        tenantId: undefined,
      },
    );

    return response.data;
  }

  static async verifyEmail(token) {
    const response = await authAxios.put(
      '/auth/verify-email',
      {
        token,
        tenantId: undefined,
      },
    );

    return response.data;
  }
}
