import service from 'src/modules/merchantTableManage/tableManageService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'MERCHANT_TABLE_MANAGE';

const actions = {
  GET_QR_CODE_START: `${prefix}_GET_QR_CODE_START`,
  GET_QR_CODE_SUCCESS: `${prefix}_GET_QR_CODE_SUCCESS`,
  GET_QR_CODE_NOT_FOUND: `${prefix}_GET_QR_CODE_NOT_FOUND`,
  CREATE_QR_CODE_START: `${prefix}_CREATE_QR_CODE_START`,
  CREATE_QR_CODE_SUCCESS: `${prefix}_CREATE_QR_CODE_SUCCESS`,

  doGetQRCode: (storeId, tableId?) => async (dispatch, getState) => {
    try {
      dispatch({ type: actions.GET_QR_CODE_START });
      const qrCodeData = await service.getQRCode(
        storeId,
        tableId);
      dispatch({
        type: actions.GET_QR_CODE_SUCCESS,
        payload: {
          qrCodeData,
        },
      });
    } catch (error) {
      if (Errors.errorCode(error) !== 404) {
        Errors.handle(error);
      }

      dispatch({
        type: actions.GET_QR_CODE_NOT_FOUND,
        payload: Errors.selectMessage(error),
      });
    }
  },

};

export default actions;
