import DishMenuService from 'src/modules/dishmenu/dishMenuService';
import DishItemService from 'src/modules/dishmenu/dishItemService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'DISH_MENU_LIST';

const actions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_NEW_MENU_SUCCESS: `${prefix}_FETCH_NEW_MENU_SUCCESS`,
  FETCH_ALL_SUCCESS: `${prefix}_FETCH_ALL_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  doChangeSelected(payload) {
    return {
      type: actions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: (restaurantId, tableId) => async (dispatch) => {
    dispatch({
      type: actions.RESETED,
    });

    dispatch(actions.doFetch(restaurantId, tableId));
  },

  doFetch: (restaurantId, tableId?) => async (dispatch) => {
    try {
      const menus = await DishMenuService.find(restaurantId, tableId);
      const items = await DishItemService.list(menus.data[0].id);
      dispatch({
        type: actions.FETCH_ALL_SUCCESS,
        payload: {
          menus: menus.data,
          items: items.categories
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FETCH_ERROR,
      });
    }
  }, 

  doFetchItems: (menuId) => async (dispatch) => {
    try {
      const items = await DishItemService.list(menuId);
      dispatch({
        type: actions.FETCH_NEW_MENU_SUCCESS,
        payload: {
          items: items.categories
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FETCH_ERROR,
      });
    }
  }
};

export default actions;
