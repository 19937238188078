import DishItemService from 'src/modules/dishmenu/dishItemService';
import DishItemRatingService from 'src/modules/dishmenu/dishItemRatingService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'DISH_MENU_VIEW';

const actions = {
  GET_STARTED: `${prefix}_GET_STARTED`,
  GET_SUCCESS: `${prefix}_GET_SUCCESS`,
  GET_ERROR: `${prefix}_GET_ERROR`,
  TYPE_COMMENT: `${prefix}_TYPE_COMMENT`,
  SUBMIT_COMMENT_STARTED: `${prefix}_SUBMIT_COMMENT_STARTED`,
  SUBMIT_COMMENT_SUCCESS: `${prefix}_SUBMIT_COMMENT_SUCCESS`,
  GET_COMMENTS_STARTED: `${prefix}_GET_COMMENTS_STARTED`,
  GET_COMMENTS_SUCCESS: `${prefix}_GET_COMMENTS_SUCCESS`,

  doGet: (itemId) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_STARTED,
      });

      const record = await DishItemService.get(itemId);

      dispatch({
        type: actions.GET_SUCCESS,
        payload: record.data.data,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GET_ERROR,
      });
    }
  },
  
  doTypeComment: (comment) => async (dispatch, get) => {
    
    dispatch({
      type: actions.TYPE_COMMENT,
    
      payload: {
        comment: comment
      }
    })
  },

  doSubmitComment: (itemId, rating, comment) => async (dispatch) => {
    dispatch({
      type: actions.SUBMIT_COMMENT_STARTED,
      payload: {
        submitting: true
      }
    })

    const record = await DishItemRatingService.create(itemId, rating, comment)

    if (record.status === 401) {
      getHistory().push({
        pathname : record.redirect,
        state : { authCallbackPath: window.location.pathname }
      })
    }

    dispatch({
      type: actions.SUBMIT_COMMENT_SUCCESS,
      payload: {
        submitting: false
      }
    })
    dispatch({
      type: actions.GET_COMMENTS_STARTED,
      payload: {
        fetchingComments: true
      }
    })

    const comments = await DishItemRatingService.fetchingComments(itemId)
    dispatch({
      type: actions.GET_COMMENTS_SUCCESS,
      payload: {
        comments: comments.data,
        ratings: comments.ratings,
        fetchingComments: false
      }
    })
  },

  doGetComments: (itemId) => async (dispatch) => {
    dispatch({
      type: actions.GET_COMMENTS_STARTED,
      payload: {
        fetchingComments: true
      }
    })

    const comments = await DishItemRatingService.fetchingComments(itemId)
    dispatch({
      type: actions.GET_COMMENTS_SUCCESS,
      payload: {
        comments: comments.data,
        ratings: comments.ratings,
        fetchingComments: false
      }
    })
  }

};

export default actions;
