import list from 'src/modules/dishmenu/list/dishMenuListReducers';
import view from 'src/modules/dishmenu/view/dishMenuViewReducers';
import header from 'src/modules/dishmenu/header/dishMenuHeaderReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  view,
  header
});
