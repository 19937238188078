import authAxios from 'src/modules/shared/axios/authAxios';
import { AuthToken } from 'src/modules/auth/authToken';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";


export default class AuthService {
  
  static async sendEmailVerification(callbackURL: string) {
    
    return firebase.auth().currentUser?.sendEmailVerification({
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: callbackURL,
      handleCodeInApp: true
    })
  }

  static async sendPasswordResetEmail(email) {
    const actionCodeSettings = {
      url: `${window.location.origin}/profile`,
    };
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
  }

  static async registerWithEmailAndPassword(
    email,
    password,
  ) {

    return firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.error(`Got error ${errorCode}: ${errorMessage}`)
      // ...firebase.auth().currentUser?.getIdToken(true)
    }).then(_ => AuthToken.setEmailPendingVerification(email))
    
  }

  static async registerProfile(
    firstName, lastName, email, phoneNumber
  ) {
    
    return firebase.firestore().collection("profiles").doc(firebase.auth().currentUser?.uid).set({
      uid: firebase.auth().currentUser?.uid,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber
    })
  }

  static async signinWithEmailAndPassword(email, password) {
    
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    return firebase.auth().signInWithEmailAndPassword(email, password)
    .then(_ => firebase.auth().currentUser?.getIdToken(true));
  }

  static async fetchMe(uid) {
    
    return firebase.firestore().collection("profiles").doc(uid).get()
    .then(function(querySnapshot) {
      return querySnapshot.data();
    })
  }

  static async isEmailConfigured() {
    const response = await authAxios.get(
      '/auth/email-configured',
    );
    return response.data;
  }

  static signout() {
    AuthToken.clear()
  }

  static async updateProfile(data, uid) {
    return firebase.firestore().collection("profiles").doc(uid).update(data)
  }

  static async verifyOldPassword(oldPassword) {
    let currentUser = firebase.auth().currentUser;
    return currentUser && firebase.auth().signInWithEmailAndPassword(currentUser.email!, oldPassword)
      .then(() => true)
      .catch(() => false)
  }

  static async changePassword(newPassword) {

    return firebase.auth().currentUser?.updatePassword(newPassword).then(response => {
      return response;
    })
  }

  static async passwordReset(token, password) {
    const response = await authAxios.put(
      '/auth/password-reset',
      {
        token,
        password,
        tenantId: undefined,
      },
    );

    return response.data;
  }

  static async verifyEmail(email: string) {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // if (email) {
      //   AuthToken.removeEmailPendingVerification()
      //   return firebase.auth().signInWithEmailLink(email, window.location.href)
      //   .then(_ => firebase.auth().currentUser?.getIdToken(true))
      // }
      return null
    }
    return null
  }
}
