import { AuthToken } from 'src/modules/auth/authToken';
import * as firebase from "firebase/app";
import "firebase/auth";

export default class PermissionChecker {
  currentUser?;
  currentMerchant?;

  constructor(currentUser?, currentMerchant?) {
    this.currentUser = currentUser;
    this.currentMerchant = currentMerchant
  }

  get isAuthenticated() {
    return (
      Boolean(this.currentUser)
    );
  }

  get isMerchantAuthenticated() {
    return (
      Boolean(this.currentMerchant)
    );
  }

  get isEmailNotVerified() {
    return firebase.auth().currentUser && !firebase.auth().currentUser?.emailVerified
  }

  get isEmailPendingVerification() {
    
    return AuthToken.getEmailPendingVerification() != null
  }
}
