import authAxios from 'src/modules/shared/axios/authAxios';
import "firebase/auth";
import "firebase/firestore";
import config from 'src/config';


export default class TableManageService {
  
  static async getQRCode(storeId: number, tableId?: number) {
    const requestUrl = !tableId ? `/qr-codes?restaurant-id=${storeId}&table-id=0` : `/qr-codes?restaurant-id=${storeId}&table-id=${tableId}`
    
    await authAxios.get(requestUrl)
    return config.backendUrl + requestUrl;
  }

  // static async createQRCode(restaurantId: number, tableId?: number) {
  //   const requestUrl = !tableId ? `/qr-codes?restaurant-id=${restaurantId}&table-id=0` : `/qr-codes?restaurant-id=${restaurantId}&table-id=${tableId}`
    
  //   await authAxios.get(requestUrl)
  //   return config.backendUrl + requestUrl;
  // }
}
