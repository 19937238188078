import authAxios from 'src/modules/shared/axios/authAxios';
import "firebase/auth";
import "firebase/firestore";


export default class StoreManageService {
  
  static async getAllManagedStores(merchantProfileId: string) {
    const response = await authAxios.get(
      `/restaurants/?owner-id=${merchantProfileId}`,
    );

    return response.data;
  }

  static async getStore(merchantProfileId: string, storeId: string) {
    const response = await authAxios.get(
      `/restaurants/?owner-id=${merchantProfileId}&store-id=${storeId}`,
    );

    return response.data;
  }

  static async create(merchantId: string, storeName: string, address?: string, phoneNumber?: number) {
    const response = await authAxios.post(`/restaurants/`, 
      JSON.stringify({
        name: storeName,
        phoneNumber: "123-456-7890",
        address: "123 Street",
        owners: [merchantId]
      })
    );

    return response.data;
  }

  static async partialUpdate(storeName?: string, address?: string, phoneNumber? : number) {
    // TODO
    const response = await authAxios.post(`/restaurants/`, 
      JSON.stringify({
        name: storeName,
        address: address,
        phoneNumber: phoneNumber
      })
    );

    return response.data;
  }
}
