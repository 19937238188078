import React from 'react';

import {
  ShopOutlined,
  UserOutlined,
  QuestionOutlined
} from '@ant-design/icons';

export enum MenuVisibility {
  Merchant,
  Customer,
  Both
}

export default [
  {
    path: '/storeManage',
    exact: true,
    icon: <ShopOutlined />,
    label: "Stores",
    visibility: MenuVisibility.Merchant
  },
  {
    path: '/profile',
    icon: <UserOutlined />,
    label: "Profile",
    visibility: MenuVisibility.Both
  },
  {
    path: '/help',
    icon: <QuestionOutlined />,
    label: "Help",
    visibility: MenuVisibility.Merchant
  },
].filter(Boolean);
