import authAxios from 'src/modules/shared/axios/authAxios';

export default class DishItemService {
  static async list(menuId) {

    const response = await authAxios.get(
      `/items/?menu-id=${menuId}`,
    );

    return response.data;
  }

  static async get(itemId) {

    const response = await authAxios.get(
      `/items/${itemId}`,
    );

    return response;
  }
}
