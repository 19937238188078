import PermissionChecker from 'src/modules/auth/permissionChecker';
import MerchantPermissionChecker from 'src/modules/merchantauth/merchantPermissionChecker';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PublicRoute({
  component: Component,
  currentUser,
  currentMerchant,
  authCallbackPath,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentUser,
        );
        const merchantPermissionChecker = new MerchantPermissionChecker(
          currentMerchant,
        );
        if (authCallbackPath) {
          return <Redirect to={authCallbackPath} />;
        }
        if (permissionChecker.isAuthenticated) {
          return <Redirect to="/profile" />;
        }
        if (merchantPermissionChecker.isAuthenticated) {
          return <Redirect to="/storeManage" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PublicRoute;
