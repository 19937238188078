import actions from 'src/modules/dishmenu/header/dishMenuHeaderActions';

const initialData = {
  restaurantData: null,
  loading: true,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.RESETED) {
    return {
      ...initialData,
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      restaurantData: payload.restaurant
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      restaurantData: null
    };
  }

  return state;
};
