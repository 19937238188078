import service from 'src/modules/merchantauth/merchantAuthService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import { MerchantAuthToken } from 'src/modules/merchantauth/merchantAuthToken';
import jwt from 'jwt-decode'

const prefix = 'MERCHANT_AUTH';

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,

  UPDATE_PROFILE_START: `${prefix}_UPDATE_PROFILE_START`,
  UPDATE_PROFILE_SUCCESS: `${prefix}_UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_ERROR: `${prefix}_UPDATE_PROFILE_ERROR`,

  PASSWORD_CHANGE_START: `${prefix}_PASSWORD_CHANGE_START`,
  PASSWORD_CHANGE_SUCCESS: `${prefix}_PASSWORD_CHANGE_SUCCESS`,
  PASSWORD_CHANGE_ERROR: `${prefix}_PASSWORD_CHANGE_ERROR`,

  CURRENT_USER_REFRESH_START: `${prefix}_CURRENT_USER_REFRESH_START`,
  CURRENT_USER_REFRESH_SUCCESS: `${prefix}_CURRENT_USER_REFRESH_SUCCESS`,
  CURRENT_USER_REFRESH_ERROR: `${prefix}_CURRENT_USER_REFRESH_ERROR`,

  PASSWORD_RESET_EMAIL_START: `${prefix}_PASSWORD_RESET_EMAIL_START`,
  PASSWORD_RESET_EMAIL_SUCCESS: `${prefix}_PASSWORD_RESET_EMAIL_SUCCESS`,
  PASSWORD_RESET_EMAIL_ERROR: `${prefix}_PASSWORD_RESET_EMAIL_ERROR`,

  PASSWORD_RESET_START: `${prefix}_PASSWORD_RESET_START`,
  PASSWORD_RESET_SUCCESS: `${prefix}_PASSWORD_RESET_SUCCESS`,
  PASSWORD_RESET_ERROR: `${prefix}_PASSWORD_RESET_ERROR`,


  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doSendPasswordResetEmail: (email) => async (dispatch) => {
    try {
      dispatch({
        type: actions.PASSWORD_RESET_EMAIL_START,
      });
      await service.sendPasswordResetEmail(email);
      Message.success(
        i18n('auth.passwordResetEmailSuccess'),
      );
      dispatch({
        type: actions.PASSWORD_RESET_EMAIL_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.PASSWORD_RESET_EMAIL_ERROR,
      });
    }
  },

  doRegisterEmailAndPassword: (merchantName, address, firstName, lastName, email, phoneNumber, password, invitationCode) => async (
    dispatch,
  ) => {
    try {
      dispatch({ type: actions.AUTH_START });

      const token = await service.registerWithEmailAndPassword(
        email,
        password,
      );

      await service.registerProfile(
        merchantName, address, firstName, lastName, email, password, phoneNumber, invitationCode);

      MerchantAuthToken.set(token, true);
      
      var storedUid = jwt(token).user_id
      const currentUser = await service.fetchMe(storedUid);
      
      dispatch({
        type: actions.AUTH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      await service.signout();

      if (Errors.errorCode(error) !== 400) {
        Errors.handle(error);
      }

      dispatch({
        type: actions.AUTH_ERROR,
        payload: Errors.selectMessage(error),
      });
    }
  },

  doSigninWithEmailAndPassword: (
    email,
    password,
    rememberMe,
  ) => async (dispatch) => {
    try {
      dispatch({ type: actions.AUTH_START });

      let currentUser;
      
      const token = await service.signinWithEmailAndPassword(
        email,
        password,
      );
      MerchantAuthToken.set(token, rememberMe);
      
      var storedUid = jwt(token).user_id
      currentUser = await service.fetchMe(storedUid);
      
      dispatch({
        type: actions.AUTH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      await service.signout();

      dispatch({
        type: actions.AUTH_ERROR,
        payload: Errors.firebaseSelectMessage(error),
      });
    }
  },

  doSignout: () => async (dispatch) => {
    try {
      dispatch({ type: actions.AUTH_START });
      await service.signout();

      dispatch({
        type: actions.AUTH_SUCCESS,
        payload: {
          currentUser: null,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.AUTH_ERROR,
      });
    }
},

  doInit: () => async (dispatch) => {
    try {
      const token = MerchantAuthToken.get();
      let currentUser;
      const timeNow = Math.round((new Date()).getTime() / 1000);
      if (token && jwt(token).exp > timeNow) {
        
        var storedUid = jwt(token).user_id
        currentUser = await service.fetchMe(storedUid);
        
      }
      dispatch({
        type: actions.AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: actions.AUTH_INIT_ERROR,
        payload: error,
      });
    }
  },

  doRefreshCurrentUser: () => async (dispatch) => {
    try {
      dispatch({
        type: actions.CURRENT_USER_REFRESH_START,
      });

      let currentUser;
      const token = MerchantAuthToken.get();
      var storedUid = jwt(token).user_id

      if (token) {
        currentUser = await service.fetchMe(storedUid);
      }

      dispatch({
        type: actions.CURRENT_USER_REFRESH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: actions.CURRENT_USER_REFRESH_ERROR,
        payload: error,
      });
    }
  },

  doUpdateProfile: (data) => async (dispatch) => {
    try {
      dispatch({
        type: actions.UPDATE_PROFILE_START,
      });

      await service.updateProfile(data);

      dispatch({
        type: actions.UPDATE_PROFILE_SUCCESS,
      });
      await dispatch(actions.doRefreshCurrentUser());
      Message.success(i18n('auth.profile.success'));
      getHistory().push('/profile');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.UPDATE_PROFILE_ERROR,
      });
    }
  },

  doChangePassword: (oldPassword, newPassword) => async (
    dispatch,
  ) => {
    try {
      dispatch({
        type: actions.PASSWORD_CHANGE_START,
      });

      await service.changePassword(
        oldPassword,
        newPassword,
      );

      dispatch({
        type: actions.PASSWORD_CHANGE_SUCCESS,
      });
      await dispatch(actions.doRefreshCurrentUser());
      Message.success(i18n('auth.passwordChange.success'));
      getHistory().push('/profile');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.PASSWORD_CHANGE_ERROR,
      });
    }
  },

  doResetPassword: (token, password) => async (
    dispatch,
  ) => {
    try {
      dispatch({
        type: actions.PASSWORD_RESET_START,
      });

      await service.passwordReset(token, password);

      Message.success(i18n('auth.passwordResetSuccess'));
      dispatch({
        type: actions.PASSWORD_RESET_SUCCESS,
      });
      getHistory().push('/');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.PASSWORD_RESET_ERROR,
      });

      dispatch(actions.doSignout());
      getHistory().push('/');
    }
  }
};

export default actions;
