import { AuthToken } from 'src/modules/auth/authToken';
import { MerchantAuthToken } from 'src/modules/merchantauth/merchantAuthToken';
import Axios from 'axios';
import config from 'src/config';
import { getLanguageCode } from 'src/i18n';
import Qs from 'qs';
import moment from 'moment';

const authAxios = Axios.create({
  baseURL: config.backendUrl,
  paramsSerializer: function (params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (
          moment.isMoment(value) ||
          value instanceof Date
        ) {
          return value.toISOString();
        }

        return value;
      },
    });
  },
});

authAxios.interceptors.request.use(
  async function (options) {
    const token = AuthToken.get() ? AuthToken.get() : MerchantAuthToken.get();

    if (token) {
      options.headers['Authorization'] = `${token}`;
      options.headers['content-type'] = "application/json"
    }

    options.headers['Accept-Language'] = getLanguageCode();

    return options;
  },
  function (error) {
    
    return Promise.reject(error);
  },
);

authAxios.interceptors.response.use(response => {
  return response;
}, error => {
  var data = {
    isError: true,
    status: error.response.status,
    error,
    redirect: ""
  }
  if (error.response.status === 401) {
    data.redirect = "/auth/signin"
  }
 return { data };
});

export default authAxios;
