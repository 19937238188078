import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import merchantauth from 'src/modules/merchantauth/merchantAuthReducers';
import dishmenu from 'src/modules/dishmenu/dishMenuReducers';
import merchantTableManage from 'src/modules/merchantTableManage/tableManageReducers';
import merchantStoreManage from 'src/modules/merchantStoreManage/storeManageReducers';
import user from 'src/modules/user/userReducers';
import settings from 'src/modules/settings/settingsReducers';
import product from 'src/modules/product/productReducers';
import order from 'src/modules/order/orderReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    user,
    settings,
    dishmenu,
    product,
    order,
    merchantauth,
    merchantTableManage,
    merchantStoreManage
  });
